import styled, { css } from "styled-components";
import { AbsFlexContainer } from "../../Styles/Global.Styled";

export const ToastContainer = styled(AbsFlexContainer)`
font-family: "Poppins", sans-serif;
${({$type}) => $type === 'Success'?
    css`
    background-color:${({theme})=>theme.green};
    `:
    $type === 'Error'?
    css`
    background-color:${({theme})=>theme.red};
    /* background-color:white; */
    `:null
};

${({$hidden})=>$hidden? 
    css`display:none;
    `:null
};
`
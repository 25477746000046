import React from "react";
import { useNavigate } from "react-router-dom";
import { FlexContainer, FlexImg, TxtSpan } from "../Styles/Global.Styled";
import logoZMAbouw from "../Assets/logoZMAbouw.png";

const Logo = ({ height }) => {
  const navigate = useNavigate()
  return (
    <FlexContainer
      $gap=".25vh"
      $align="center"
      $height={`${height}vh`}
      style={{ cursor: "pointer" }}
      onClick={()=>navigate('/')}
    >
      <FlexImg src={logoZMAbouw} $height="90%" />
      <FlexContainer $flexD="column" $height="100%">
        <FlexContainer $align="center">
          <TxtSpan
            $color="main"
            $fSize={height ? `${height / 2}vh` : "5vh"}
            $mFSize={height ? `${height / 2}vh` : "4vh"}
            $fWeight="800"
            $font="fontSec"
            style={{ fontStyle: "italic" }}
          >
            ZMA
          </TxtSpan>
          <TxtSpan
            // $height="100%"
            $color="main"
            $fSize={height ? `${height / 2}vh` : "5vh"}
            $mFSize={height ? `${height / 2}vh` : "4vh"}
            $fWeight="400"
            $font="defaultFont"
            style={{ marginTop: ".9vh" }}
          >
            bouw
          </TxtSpan>
        </FlexContainer>
        <TxtSpan
          $fSize={height ? `${height / 5}vh` : "1.5vh"}
          $mFSize={height ? `${height / 5}vh` : "1.25vh"}
          $color="secColor"
          $fWeight="600"
          $font="defaultFont"
          style={{ whiteSpace: "nowrap" }}
        >
          Zetten de puntjes op de afbouw
        </TxtSpan>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Logo;

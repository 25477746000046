import React from "react";

const EnvelopeIcon = ({ style, color }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 13"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4854 1.9043C15.4854 1.0793 14.8104 0.404297 13.9854 0.404297H1.98535C1.16035 0.404297 0.485352 1.0793 0.485352 1.9043V10.9043C0.485352 11.7293 1.16035 12.4043 1.98535 12.4043H13.9854C14.8104 12.4043 15.4854 11.7293 15.4854 10.9043V1.9043ZM13.9854 1.9043L7.98535 5.6543L1.98535 1.9043H13.9854ZM13.9854 10.9043H1.98535V3.4043L7.98535 7.1543L13.9854 3.4043V10.9043Z"
        fill={color ? color : "#5A5A5A"}
      />
    </svg>
  );
};

export default EnvelopeIcon;

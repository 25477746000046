import axios from "axios";

const REACT_APP_API_ENDPOINT = "https://www.api.zmabouw.nl:5001/"
// const REACT_APP_API_ENDPOINT = "http://localhost:5001/"

export const requestQuote = async (req) => {
    const res = await axios.post(REACT_APP_API_ENDPOINT + "qoute", req)
    return res
}
export const requestContact = async (req) => {
    const res = await axios.post(REACT_APP_API_ENDPOINT + "contact", req)
    return res
}
export const testServer = async () => {
    const res = await axios.get(REACT_APP_API_ENDPOINT)
    console.log("testServer res",res)
    return res
}
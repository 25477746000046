import React from "react";
import {
  Dot,
  FlexContainer,
  FlexImg,
  H1,
  Paragraph,
  TxtSpan,
} from "../Styles/Global.Styled";
import PageHeader from "../Components/PageTitle/PageHeader";
import OnzeTeam2 from "../Assets/OnzeTeam2.jpg";
import { theme } from "../Styles/theme";
import { companyDetails } from "../helpers/data";
import KlantenVriendelijk from "../Assets/KlantenVriendelijk.jpg";

const whyUsContent = [
  {
    title: "Kwaliteit en Betrouwbaarheid:",
    text: "Wij gebruiken alleen de beste materialen en de nieuwste technieken om ervoor te zorgen dat uw installaties, reparaties en onderhoud duurzaam en efficiënt zijn.",
  },
  {
    title: "Deskundigheid en Professionaliteit:",
    text: "Onze professionals zijn bekwaam, gecertificeerd en worden continu getraind om aan de normen van de industrie te voldoen",
  },
  {
    title: "Uitzonderlijke Klanttevredenheid:",
    text: "Wij geven prioriteit aan uw behoeften en zijn toegewijd aan het leveren van diensten die uw verwachtingen overtreffen.",
  },
];

const OverOns = () => {
  return (
    <FlexContainer $width="100%" $align="center" $flexD="column">
      <PageHeader title="Over ons" />
      {/* Wie zijn wij section */}
      <FlexContainer
        id="wie-wij-zijn"
        $padding="10vh 25vh"
        $mPadding="5vh 2vh"
        $gap="5vh"
        $width="100%"
        $mFlexD="column-reverse"
      >
        <FlexImg
          src={OnzeTeam2}
          $height="40vh"
          $mHeight="25vh"
          $objectFit="fill"
          $bRadius="12px"
          $border={`4px solid ${theme.main}`}
        />
        <FlexContainer $flexD="column" $gap="2vh">
          <H1 $color="secColor" $fSize="5vh">
            Wie Wij Zijn
          </H1>
          <Paragraph $mFSize="2vh">
            Bij {companyDetails.name} zetten we ons in om eersteklas
            klusjesmannendiensten te leveren die elke behoefte aan huis- en
            kantooronderhoud dekken. Opgericht in 2024, is ons doel altijd
            geweest om betrouwbare, efficiënte en innovatieve oplossingen te
            bieden aan elke huiseigenaar en onderneming. Van eenvoudige
            reparaties tot complexe renovaties, ons team is uitgerust met de
            expertise en gereedschappen die nodig zijn om elke taak aan te
            pakken.
          </Paragraph>
        </FlexContainer>
      </FlexContainer>
      {/* Onze missie section */}
      <FlexContainer
        id="onze-missie"
        $padding="5vh 60vh"
        $mPadding="5vh 3vh"
        $gap="2vh"
        $bgColor="main"
        $flexD="column"
        $align="center"
      >
        <H1 $color="secColor" $fSize="5vh">
          Onze Missie
        </H1>
        <Paragraph $txtAlign="center" $mFSize="2vh">
          Onze missie is om te garanderen dat elke klus die we ondernemen wordt
          voltooid volgens de hoogste normen van kwaliteit en klanttevredenheid.
          We geloven in het opbouwen van duurzame relaties met onze klanten door
          uitstekende service en betrouwbaarheid.
        </Paragraph>
      </FlexContainer>
      {/* Waarom Kiezin Voor Ons section */}
      <FlexContainer
        id="waarom-kiezen-voor-ons"
        $padding="10vh 25vh"
        $mPadding="5vh 3vh"
        $gap="5vh"
        $width="100%"
        $mFlexD="column"
      >
        <FlexContainer $flexD="column" $gap="3vh">
          <H1 $color="secColor" $fSize="5vh" $mFSize="4vh">
            Waarom Kiezen Voor Ons?
          </H1>
          {whyUsContent.map((item) => (
            <FlexContainer $gap=".5vh" $mFlexD="column">
              <FlexContainer>
                <Dot $margin=".75vh .5vh" $width="1vh" $height="1vh" />
                <TxtSpan
                  $align="flex-start"
                  $fSize="1.75vh"
                  $width="31vh"
                  $fWeight="800"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {item.title}
                </TxtSpan>
              </FlexContainer>
              <TxtSpan $fSize="1.75vh">{item.text}</TxtSpan>
            </FlexContainer>
          ))}
        </FlexContainer>
        <FlexImg
          src={KlantenVriendelijk}
          $height="35vh"
          $mHeight="25vh"
          $bRadius="12px"
          $border={`4px solid ${theme.main}`}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default OverOns;

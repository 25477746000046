import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AbsFlexContainer,
  CustomButton,
  FlexContainer,
  Line,
} from "../../Styles/Global.Styled";
import { pagesContent } from "../../helpers/data";
import CallButton from "../../Components/Buttons/CallButton";
import OfferteButton from "../../Components/Buttons/OfferteButton";

const MobileNavBar = ({ setPopUp }) => {
  const navigate = useNavigate();
  
  return (
    <AbsFlexContainer
      $position="fixed"
      $display="none"
      $mDisplay="flex"
      $height="100%"
      $width="100vw"
      $justifyC="flex-end"
      style={{ zIndex: "5" }}
    >
      {/* Overlay component (button) */}
      <FlexContainer
        $bgColor="shadowColor"
        $width="30%"
        $height="100%"
        onClick={()=>setPopUp(false)}
        style={{ zIndex: "6" }}
      />
      <FlexContainer
        $bgColor="thirdColor"
        $flexD="column"
        $width="70%"
        $height="100%"
        $padding="2vh"
        $bRadius="6px 0 0 6px"
      >
        {/* Close window button */}
        <CustomButton
          $color="main"
          $width="4vh"
          $height="4vh"
          $fSize="2.75vh"
          $font="fontThrid"
          $fWeight="600"
          $alignSelf="flex-end"
          $bRadius="6px"
          onClick={() => setPopUp(false)}
        >
          X
        </CustomButton>
        {/* Pages buttons */}
        <FlexContainer
          $gap="3vh"
          $align="flex-start"
          $padding="3vh 0"
          $flexD="column"
          // $border="2px solid red"
        >
          {pagesContent.map((page) => (
            <CustomButton
              key={page.name}
              $padding="0 2vh"
              $fSize="2.25vh"
              $fWeight="600"
              $color="main"
              onClick={() => {setPopUp(false);navigate(page.path)}}
            >
              {page.name}
            </CustomButton>
          ))}
        </FlexContainer>
        <Line $margin="2vh 0 1vh 0" />
        {/* Action buttons */}
        <FlexContainer $flexD="column" $gap="4vh" $padding="5vh 0">
          <CallButton/>
          <OfferteButton setPopUp={setPopUp}/>
        </FlexContainer>
      </FlexContainer>
    </AbsFlexContainer>
  );
};

export default MobileNavBar;

import React from "react";
import {
  AbsTxtSpan,
  DynamicLabel,
  FlexContainer,
  IsRequiredStar,
  TextArea,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";

const TextAreaInput = ({
  width,
  mWidth,
  height,
  mHeight,
  properties,
  setForm,
  index,
}) => {
  const {
    label,
    required,
    value,
    validator,
    minLength,
    maxLength,
    errors,
    disabled,
  } = properties ?? {};

  const runValidation = (e) => {
    const { value } = e.target;
    if (typeof validator === "function") {
      setForm((prev) => {
        const formData = [...prev];
        formData[index].errors = validator(value, maxLength);
        return formData;
      });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setForm((prev) => {
      const formData = [...prev];
      formData[index].value = value;
      return formData;
    });
    if (errors !== null) {
      runValidation(e);
    }
  };

  return (
    <FlexContainer
      $width={width}
      $mWidth={mWidth}
      $height={height}
      $mHeight={mHeight}
    >
      <TextArea
        $border={`1px solid ${errors ? "red" : theme.secColor}`}
        $width="100%"
        $mFSize="2.25vh"
        $fSize=".85vw"
        $padding="0.5vw"
        $mPadding="1vh"
        minLength={minLength}
        maxLength={maxLength}
        required={required}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onBlur={runValidation}
      />
      <DynamicLabel
        $position="absolute"
        $height="1.5vh"
        $top="-1vh"
        $left="1vh"
        $fSize="1.75vh"
        $zIndex="1" 
        $bgColor="white"
      >
        {label}
        {required ? <IsRequiredStar /> : null}
      </DynamicLabel>
      <DynamicLabel
        $position="absolute"
        $left="70%"
        $top="92%"
        $fSize="1.25vh"
        $mFSize="1.4vh"
        $bgColor="white"
      >
        {`${value.length + "/" + maxLength} woorden`}
        {required ? <IsRequiredStar /> : null}
      </DynamicLabel>
      {errors ? (
        <AbsTxtSpan
          $color="red"
          $fSize="1.25vh"
          $height="1.5vh"
          $bottom="-1.9vh"
        >
          {errors}
        </AbsTxtSpan>
      ) : null}
    </FlexContainer>
  );
};

export default TextAreaInput;

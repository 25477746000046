import styled from "styled-components";
import { sizes, theme } from "./theme";

export const FlexContainer = styled.div`
  border: ${({ $border }) => $border};
  box-sizing: border-box;
  position: relative;
  display: ${({ $display }) => $display || "flex"};
  flex-direction: ${({ $flexD }) => $flexD};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyC }) => $justifyC};
  width: ${({ $width }) => $width};
  max-width: ${({ $maxW }) => $maxW};
  min-width: ${({ $minW }) => $minW};
  height: ${({ $height }) => $height};
  flex-grow: ${({ $flexG }) => $flexG};
  gap: ${({ $gap }) => $gap};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  color: ${({ theme, $color }) => theme[$color]};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
  font-size: ${({ $fSize }) => $fSize};
  border-radius: ${({ $bRadius }) => $bRadius};
  box-shadow: ${({ $bShadow }) => $bShadow};
  /* overflow: ${({ $overflow }) => $overflow || "hidden"}; */
  @media (max-width: ${sizes.laptopL}) {
    border: ${({ $mBorder }) => $mBorder};
    display: ${({ $mDisplay }) => $mDisplay};
    align-items: ${({ $mAlign }) => $mAlign};
    justify-content: ${({ $mJustifyC }) => $mJustifyC};
    flex-direction: ${({ $mFlexD }) => $mFlexD};
    flex-wrap: ${({ $mFlxWrap }) => $mFlxWrap};
    flex-grow: ${({ $mFlxG }) => $mFlxG};
    font-size: ${({ $mFSize }) => $mFSize};
    gap: ${({ $mGap }) => $mGap};
    margin: ${({ $mMargin }) => $mMargin};
    padding: ${({ $mPadding }) => $mPadding};
    width: ${({ $mWidth }) => $mWidth};
    max-width: ${({ $mMaxW }) => $mMaxW};
    min-width: ${({ $mMinW }) => $mMinW};
    height: ${({ $mHeight }) => $mHeight};
    overflow: ${({ $mOverflow }) => $mOverflow};
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const AbsFlexContainer = styled(FlexContainer)`
  position: ${({ $position }) => $position || "absolute"};
  display: ${({ $display }) => $display};
  align-items: ${({ $align }) => $align || "center"};
  justify-content: ${({ $justifyC }) => $justifyC || "center"};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
  bottom: ${({ $bottom }) => $bottom};
  color: white;
  @media (max-width: ${sizes.laptopL}) {
    display: ${({ $mDisplay }) => $mDisplay};
  }
`;


export const FlexImg = styled.img`
  border: ${({ $border }) => $border};
  box-shadow: ${({ $bShadow }) => $bShadow};
  position: ${({ $position }) => $position};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  aspect-ratio: ${({ $aspectR }) => $aspectR};
  border-radius: ${({ $bRadius }) => $bRadius};
  object-fit: ${({ $objectFit }) => $objectFit || "cover"};
  padding: ${({ $padding }) => $padding};
  @media (max-width: ${sizes.laptopL}) {
    display: ${({ $mDisplay }) => $mDisplay};
    padding: ${({ $mPadding }) => $mPadding};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;

export const CustomButton = styled.button`
  border: ${({ $border }) => $border || "none"};
  position: relative;
  font-family: ${({ theme, $font }) => theme[$font] || "Poppins, sans-serif"};
  display: ${({ $display }) => $display || "flex"};
  align-items: ${({ $align }) => $align || "center"};
  justify-content: ${({ $justifyC }) => $justifyC || "center"};
  flex-direction: ${({ $flexD }) => $flexD};
  align-self: ${({ $alignSelf }) => $alignSelf};
  font-size: ${({ $fSize }) => $fSize};
  font-weight: ${({ $fWeight }) => $fWeight};
  border-radius: ${({ $bRadius }) => $bRadius};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding || 0};
  margin: ${({ $margin }) => $margin};
  color: ${({ $color }) => theme[$color] || "black"};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor] || "inherit"};
  gap: ${({ $gap }) => $gap};
  box-shadow: ${({ $bShadow }) => $bShadow};
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  @media (max-width: ${sizes.laptopL}) {
    display: ${({ $mDisplay }) => $mDisplay || "flex"};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
    font-size: ${({ $mFSize }) => $mFSize};
    padding: ${({ $mPadding }) => $mPadding};
    gap: ${({ $mGap }) => $mGap};
    margin: ${({ $mMargin }) => $mMargin};
  }
`;
export const AbsButton = styled(CustomButton)`
  position: absolute;
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  @media (max-width: ${sizes.laptopL}) {
    top: ${({ $mTop }) => $mTop};
    right: ${({ $mRight }) => $mRight};
    left: ${({ $mLeft }) => $mLeft};
    bottom: ${({ $mBottom }) => $mBottom};
  }
`;

export const H1 = styled.h1`
  font-size: 58px;
  margin: ${({ $margin }) => $margin || 0};
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight};
  text-align: ${({ $txtAlign }) => $txtAlign};
  color: ${({ theme, $color }) => theme[$color]};
  font-size: ${({ $fSize }) => $fSize};
  @media (max-width: ${sizes.laptopL}) {
    margin: ${({ $mMargin }) => $mMargin};
    text-align: ${({ $mTxtAlign }) => $mTxtAlign};
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const H2 = styled.h2`
  display: ${({ $display }) => $display};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyC }) => $justifyC};
  flex-direction: ${({ $flexD }) => $flexD};
  text-align: ${({ $txtAlign }) => $txtAlign};
  margin: ${({ $margin }) => $margin || 0};
  position: ${({ $position }) => $position};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight};
  color: ${({ theme, $color }) => theme[$color] || theme.main};
  text-align: ${({ $txtAlign }) => $txtAlign};
  font-size: ${({ $fSize }) => $fSize};
  @media (max-width: ${sizes.laptopL}) {
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const H3 = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: ${({ $fSize }) => $fSize};
  position: ${({ $position }) => $position};
  width: ${({ $width }) => $width || null};
  height: ${({ $height }) => $height || null};
  margin: ${({ $margin }) => $margin || 0};
  font-weight: ${({ $fWeight }) => $fWeight};
  color: ${({ $color, theme }) => theme[$color]};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  text-align: ${({ $txtAlign }) => $txtAlign};
  @media (max-width: ${sizes.laptopL}) {
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
    top: ${({ $mTop }) => $mTop};
  }
`;
export const H4 = styled.h4`
  font-family: "Poppins", sans-serif;
  text-align: ${({ $txtAlign }) => $txtAlign || "center"};
  color: ${({ theme, $color }) => ($color ? theme[$color] : theme.secColor)};
  font-weight: ${({ $fWeight }) => $fWeight || 600};
  font-size: ${({ $fSize }) => $fSize};
  width: ${({ $width }) => $width || null};
  height: ${({ $height }) => $height || null};
  margin: ${({ $margin }) => $margin || 0};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  @media (max-width: ${sizes.laptopL}) {
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
  }
`;
export const FlexH4 = styled(H4)`
  display: flex;
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyC }) => $justifyC};
  gap: ${({ $gap }) => $gap};
  padding: ${({ $padding }) => $padding};
  border-radius: ${({ $bRadius }) => $bRadius};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
  font-size: ${({ $fSize }) => $fSize};
  @media (max-width: ${sizes.laptopL}) {
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;

export const Form = styled.form`
  border: ${({ $border }) => $border};
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: ${({ $flexD }) => $flexD || "column"};
  align-items: ${({ $align }) => $align};
  justify-content: ${({ $justifyC }) => $justifyC};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  gap: ${({ $gap }) => $gap};
  padding: ${({ $padding }) => $padding};
  margin: ${({ $margin }) => $margin};
  font-size: ${({ $fSize }) => $fSize};
  border-radius: ${({ $bRadius }) => $bRadius};
  box-shadow: ${({ $bShadow }) => $bShadow};
  @media (max-width: ${sizes.laptopL}) {
    padding: ${({ $mPadding }) => $mPadding};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
    gap: ${({ $mGap }) => $mGap};
  }
`;

export const CheckBoxInput = styled.input`
  flex-grow: 0;

  &:checked {
    background-color: #008000; /* Background color when checked */
    background-color: red; /* Background color when checked */
    border-color: #008000; /* Border color when checked */
  }

  /* Hover state */
  &:hover {
    cursor: pointer;
  }
`;
export const Input = styled.input`
  box-sizing: border-box;
  display: flex;
  align-items: ${({ $align }) => $align || "center"};
  justify-content: ${({ $justifyC }) => $justifyC};
  border: ${({ $border }) => $border || "none"};
  padding: ${({ $padding }) => $padding};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height || "100%"};
  background-color: inherit;
  font-size: 1vw;
  outline: none;
  border-radius: ${({ $bRadius }) => $bRadius};
  text-align: ${({ $txtAlign }) => $txtAlign};
  &::placeholder {
    color: #008000;
  }
  -webkit-appearance: textfield; /* WebKit browsers */
  -moz-appearance: none; /* Firefox */
  appearance: textfield; /* Standard */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
  @media (max-width: ${sizes.laptopL}) {
    display: ${({ $mDisplay }) => $mDisplay};
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;
export const DynamicLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyC }) => $justifyC};
  z-index: ${({ $zIndex }) => $zIndex || 0};
  position: ${({ $position }) => $position};
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight || 200};
  font-size: ${({ $fSize }) => $fSize};
  padding: ${({ $padding }) => $padding};
  height: ${({ $height }) => $height};
  width: ${({ $width }) => $width};
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
  @media (max-width: ${sizes.laptopL}) {
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const Select = styled.select`
  box-sizing: border-box;
  border: ${({ $border }) => $border};
  font-family: "Poppins", sans-serif;
  flex-grow: 1;
  height: 100%;
  border-radius: 6px;
  font-weight: ${({ $fWeight }) => $fWeight};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
  font-size: ${({ $fSize }) => $fSize};
  box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.shadowColor};
  color: ${({ theme, $color }) => theme[$color] || theme.color4};
  @media (max-width: ${sizes.laptopL}) {
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const TextArea = styled.textarea`
  border: ${({ $border }) => $border};
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  font-size: ${({ $fSize }) => $fSize};
  padding: ${({ $padding }) => $padding};
  border-radius: ${({ $bRadius }) => $bRadius || "6px"};
  font-size: ${({ $fSize }) => $fSize};
  /* line-height: 1.5; */
  white-space: pre-line;
  outline: none;
  resize: none;
  @media (max-width: ${sizes.laptopL}) {
    padding: ${({ $mPadding }) => $mPadding};
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;

export const Paragraph = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $fWeight }) => $fWeight};
  font-size: ${({ $fSize }) => $fSize || "22px"};
  margin: 0;
  text-align: ${({ $txtAlign }) => $txtAlign};
  color: ${({ theme, $color }) => theme[$color] || theme.thirdColor};
  @media (max-width: ${sizes.laptopL}) {
    font-size: ${({ $mFSize }) => $mFSize};
  }
`;
export const TxtSpan = styled.span`
  font-family: ${({ theme, $font }) => theme[$font] || "Poppins, sans-serif"};
  display: ${({ $display }) => $display || "flex"};
  align-items: ${({ $align }) => $align || "center"};
  color: ${({ $color, theme }) => theme[$color] || "black"};
  font-size: ${({ $fSize }) => $fSize};
  font-weight: ${({ $fWeight }) => $fWeight};
  font-style: ${({ $fStyle }) => $fStyle};
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  padding: ${({ $padding }) => $padding};
  background-color: ${({ theme, $bgColor }) => theme[$bgColor]};
  border-radius: ${({ $bRadius }) => $bRadius};
  @media (max-width: ${sizes.laptopL}) {
    display: ${({ $mDisplay }) => $mDisplay};
    font-size: ${({ $mFSize }) => $mFSize};
    width: ${({ $mWidth }) => $mWidth};
  }
`;
export const AbsTxtSpan = styled(TxtSpan)`
  position: absolute;
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
`;

export const Line = styled.div`
  box-sizing: border-box;
  border-style: solid;
  border-top: none;
  border-width: ${({ $bWidth }) => $bWidth || "2px"};
  border-color: ${({ $bColor }) => theme[$bColor] || theme.main};
  width: ${({ $width }) => $width || "100%"};
  height: ${({ $height }) => $height || "0"};
  border-radius: 75px;
  margin: ${({ $margin }) => $margin};
  @media (max-width: ${sizes.laptopL}) {
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;

export const Dot = styled.div`
  width: ${({ $width }) => $width || "10px"};
  height: ${({ $height }) => $height || "10px"};
  background-color: ${({ $bgColor, theme }) =>
    $bgColor ? theme[$bgColor] : theme.secColor};
  border-radius: 50%;
  margin: ${({ $margin }) => $margin || "0 .5vh"};
  @media (max-width: ${sizes.laptopL}) {
    width: ${({ $mWidth }) => $mWidth};
    height: ${({ $mHeight }) => $mHeight};
  }
`;
export const IndexedDot = styled.div`
  position: absolute;
  padding: ${({ $padding }) => $padding};
  font-size: ${({ $fSize }) => $fSize};
  border-radius: 50%;
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
  color: white;
  background-color: ${({ theme }) => theme.main};
  width: ${({ $width }) => $width || "2vh"};
  height: ${({ $height }) => $height || "2vh"};
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IsRequiredStar = styled.span`
  /* z-index: 2; */
  color: red !important;
  /* font-weight: bold; */
  &::before {
    content: "*";
  }
`;

export const ItemAfter = styled.div`
  position: absolute;
  content: " ";
  height: ${({ $height }) => $height || "5vh"};
  width: ${({ $width }) => $width || "5vh"};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  /* right: ${({ $right }) => $right}; */
  z-index: 0;
  border-bottom-left-radius: 50%;
  box-shadow: ${({ $size }) => $size || "-1vh 1vh"} 0
    ${({ theme, $color }) => theme[$color] || theme.main};
`;

export const ItemBefore = styled.div`
  position: absolute;
  content: " ";
  height: ${({ $height }) => $height || "5vh"};
  width: ${({ $width }) => $width || "5vh"};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  right: ${({ $right }) => $right};
  z-index: 0;
  border-bottom-right-radius: ${({ $bRadius }) => $bRadius || "50%"};
  box-shadow: ${({ $size }) => $size || "1vh 1vh"} 0
    ${({ theme, $color }) => theme[$color] || theme.main};
`;

export const ItemLeft = styled.div`
  position: absolute;
  content: " ";
  height: ${({ $height }) => $height || "5vh"};
  width: ${({ $width }) => $width || "5vh"};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  /* right: ${({ $right }) => $right}; */
  z-index: 0;
  border-top-left-radius: 50%;
  box-shadow: ${({ $size }) => $size || "-1vh -1vh"} 0
    ${({ theme, $color }) => theme[$color] || theme.main};
`;
export const ItemTop = styled.div`
  position: absolute;
  content: " ";
  height: ${({ $height }) => $height || "5vh"};
  width: ${({ $width }) => $width || "5vh"};
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  bottom: ${({ $bottom }) => $bottom};
  right: ${({ $right }) => $right};
  z-index: 0;
  border-top-right-radius: 50%;
  box-shadow: ${({ $size }) => $size || "1vh -1vh"} 0
    ${({ theme, $color }) => theme[$color] || theme.main};
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../Styles/Global.Styled";
import FormIcon from "../../Icons/FormIcon";
import { theme } from "../../Styles/theme";

const OfferteButton = ({setPopUp}) => {
  const navigate = useNavigate();
  return (
    <CustomButton
      $padding="1vh"
      $mPadding="1vh .5vh"
      $bRadius="6px"
      $color="secColor"
      $fSize="2vh"
      $bgColor="main"
      $fWeight="600"
      $gap=".5vh"
      onClick={() => {navigate("/offerte-aanvragen");setPopUp(false)}}
    >
      <FormIcon
        color={`${theme.secColor}`}
        style={{ width: "2vh" }}
      />
      Offerte Aanvragen
    </CustomButton>
  );
};

export default OfferteButton;

import React from "react";
import styled from "styled-components";
import {
  FlexContainer,
  AbsButton,
  Dot,
  FlexH4,
  FlexImg,
  H3,
} from "../../Styles/Global.Styled";
import useNavigateAndScroll from "../../Hooks/useNavigateAndScroll";

const ContainerHover = styled(FlexContainer)`
  &:hover {
    cursor: pointer;
    transform: scale(0.95);
  }
`;

const ServiceCard = ({ service, isActive, onHover, index, serviceId }) => {
  const navigateAndScroll = useNavigateAndScroll();
  return (
    <ContainerHover
      $bRadius="6px"
      $flexG="1"
      $mWidth="49%"
      key={service.name}
      $flexD="column"
      $gap="1vh"
      $justifyC="space-between"
      $padding="1vh"
      $mPadding=".25vh"
      onTouchStart={() => onHover(index)}
      onTouchEnd={() => onHover(null)}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onHover(null)}
      $isActive={isActive}
      onClick={() =>
        navigateAndScroll(`/onze-diensten/${serviceId}`, service.id)
      }
    >
      {isActive && (
        <AbsButton
          $bRadius="6px"
          $align="flex-start"
          $width="100%"
          $height="100%"
          $padding="15vh 0 0 0"
          $mPadding="12vh 0 0 0"
          $fSize="1.75vh"
          $top="0"
          $left="0"
          style={{
            zIndex: "1",
            textDecoration: "underline",
            textDecorationThickness: "2px",
            textUnderlineOffset: ".25vh",
            whiteSpace: "nowrap",
            // transform: "translate(-50%, -50%)",
          }}
          $bgColor="shadowColor"
          $color="main"
        >
          Bijk meer details
        </AbsButton>
      )}
      <FlexImg
        src={service.bgImage}
        $width="100%"
        $height="17.5vh"
        $mHeight="15vh"
        $bRadius="6px"
        $objectFit="cover"
        style={{
          filter: "brightness(50%)",
        }}
      />
      <H3
        $position="absolute"
        // $width="75%"
        $color="main"
        $left="50%"
        $top="6vh"
        $fSize="2.5vh"
        $mFSize={"1.75vh"}
        style={{ transform: "translateX(-50%)"}}
        $txtAlign="center"
      >
        {service.name}
      </H3>
      {service.examples.map((x) => (
        <FlexH4
          $align="center"
          $mFSize="1.5vh"
          $color={isActive && "thirdColor"}
          key={x}
          style={{ zIndex: "1", textAlign: "start" }}
        >
          <FlexContainer
            $align="center"
            $mAlign="flex-start"
            $mHeight="100%"
            $mPadding=".5vh 0 0 0"
          >
            <Dot
              $bgColor={isActive && "thirdColor"}
              $width="1vh"
              $height="1vh"
              $mWidth=".75vh"
              $mHeight=".75vh"
            />
          </FlexContainer>
          {x}
        </FlexH4>
      ))}
    </ContainerHover>
  );
};

export default ServiceCard;

import React from "react";
import { FlexContainer, H1 } from "../../Styles/Global.Styled";

const PageHeader = ({ title }) => {
  return (
    <FlexContainer
      $bgColor="main"
      $width="100%"
      $height="20vh"
      $justifyC="center"
      $align="center"
    >
      <H1
        $color="secColor"
        $fSize="clamp(4vh, 6vw, 7vh)"
        $txtAlign="center"
      >
        {title}
      </H1>
    </FlexContainer>
  );
};

export default PageHeader;

// useNavigateAndScroll.js
import { useNavigate } from 'react-router-dom';

function useNavigateAndScroll() {
    const navigate = useNavigate();

    const navigateAndScroll = (path, sectionId) => {
        // Navigates and passes the sectionId in the state
        navigate(path, { state: { sectionId } });
    };

    return navigateAndScroll;
}

export default useNavigateAndScroll;

const convertArrayToObject = (formData) => {
  return formData.reduce((acc, item) => {
    if (item.value) {
      // Check if the value is not empty
      acc[item.name] = item.value;
    }
    return acc;
  }, {});
};

export const convertContactForm = (formData) => {
  return convertArrayToObject(formData);
};

export const cleanUpFormValue = (formData) => {
  return formData.map(item => {
    item.value = ""
    return item
  })
}

export const smoothHorizontalScrolling = (container, time, start, end) => {
  let change = end - start,
    currentTime = 0,
    increment = 20; // Adjust increment to control the smoothness

  const animateScroll = function () {
    currentTime += increment;
    let val = Math.easeInOutQuad(currentTime, start, change, time);
    container.scrollLeft = val;
    if (currentTime < time) {
      requestAnimationFrame(animateScroll);
    }
  };

  animateScroll();
};

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { AbsButton, CustomButton, FlexContainer, Line } from "../../Styles/Global.Styled";
import Logo from "../../Components/Logo";
import { theme } from "../../Styles/theme";
import { pagesContent } from "../../helpers/data";
import styled from "styled-components";
import useScrollDirection from "../../Hooks/useScrollDirection";
import CallButton from "../../Components/Buttons/CallButton";
import OfferteButton from "../../Components/Buttons/OfferteButton";

const NavBarContainer = styled(FlexContainer)`
  transition: transform 0.5s ease-in-out;
  position: sticky;
  top: 0;
  z-index: 4;
  overflow-x: hidden;
  transform: ${({ $hide }) => ($hide ? "translateY(-100%)" : "translateY(0)")};
`;

const NavBar = ({ setPopUp }) => {
  const navigate = useNavigate();
  const scrollDirection = useScrollDirection();
  
  return (
    <NavBarContainer
      $width="100%"
      $height="10vh"
      $mHeight="8vh"
      $bgColor="thirdColor"
      $padding="0 10vh"
      $mPadding="0 1vh"
      $hide={scrollDirection === "down"}
      $gap="5vh"
      $bShadow={`2px -2px 2px ${theme.shadowColor} inset`}
    >
      <Logo />
      {/* Mobile Menu Button */}
      <AbsButton
        $display="none"
        $mDisplay="flex"
        $color="main"
        $width="4vh"
        $height="4vh"
        $flexD="column"
        $justifyC="space-evenly"
        $border={`2px solid ${theme.main}`}
        $padding="0 .5vh"
        $bRadius="6px"
        $right="2vh"
        $top="2vh"
        onClick={() => setPopUp(true)}
      >
        <Line />
        <Line />
        <Line />
      </AbsButton>
      {/* Desktop Pages Buttons */}
      <FlexContainer $mDisplay="none" $gap="1vh" $flexG="1" $align="center">
        {pagesContent.map((page) => (
          <CustomButton
            key={page.name}
            $padding="0 1.75vh"
            $fSize="2vh"
            $fWeight="600"
            $color="main"
            onClick={() => navigate(page.path)}
          >
            {page.name}
          </CustomButton>
        ))}
      </FlexContainer>
      {/* Desktop call to action Buttons */}
      <FlexContainer $mDisplay="none" $align="center" $gap="2vh">
        <CallButton />
        <OfferteButton setPopUp={setPopUp}/>
      </FlexContainer>
    </NavBarContainer>
  );
};

export default NavBar;

import React from "react";
import { FlexContainer, TxtSpan } from "../Styles/Global.Styled";
import CheckmarkIcon from "../Icons/CheckmarkIcon";

const FormMessage = ({ width, mWidth, height, mHeight, title, message }) => {
  return (
    <FlexContainer
      $flexD="column"
      $gap="1vh"
      $align="center"
      $justifyC="center"
    >
      <FlexContainer
        $width={width}
        $mWidth={mWidth}
        $height={height}
        $mHeight={mHeight}
        $align="center"
        $justifyC="center"
        $bgColor="green"
        $bRadius="50%"
      >
        <CheckmarkIcon style={{ height: "50%", width: "50%" }} color="white" />
      </FlexContainer>
      <TxtSpan
        $font="fontSec"
        $fWeight="700"
        $fSize="4vh"
        $mFSize="3vh"
        style={{ textAlign: "center" }}
      >
        {title}
      </TxtSpan>
      <TxtSpan style={{ textAlign: "center" }}>{message}</TxtSpan>
    </FlexContainer>
  );
};

export default FormMessage;

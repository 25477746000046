import React from "react";
import { CustomButton } from "../../Styles/Global.Styled";
import PhoneIcon from "../../Icons/PhoneIcon";
import { theme } from "../../Styles/theme";
import { companyDetails } from "../../helpers/data";

const CallButton = () => {
  const handleCall = () => {
    window.location.href = `tel:${companyDetails.phoneNoCall}`
  }
  return (
    <CustomButton
      $padding="1vh"
      $bRadius="6px"
      $color="main"
      $fSize="2.2vh"
      $bgColor="secColor"
      $fWeight="600"
      $gap="1vh"
      onClick={()=>handleCall()}
    >
      <PhoneIcon
        color={`${theme.main}`}
        style={{ height: "2vh", width: "2vh" }}
      />
      {companyDetails.phoneNo}
    </CustomButton>
  );
};

export default CallButton;

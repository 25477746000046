import Loodgieterswerk from "../Assets/Loodgieterswerk.jpg";
import ElektrischeReparatie from "../Assets/ElektrischeReparatie.jpg";
import Timmerwerk from "../Assets/Timmerwerk.jpg";
import Dakreparaties from "../Assets/Dakreparaties.jpg";
import Keukenapparatuur from "../Assets/Keukenapparatuur.jpg";
import Badkamerarmatuur from "../Assets/Badkamerarmatuur.jpg";
import Verwarmingssystemen from "../Assets/Verwarmingssystemen.jpg";
import Beveiligingssystemen from "../Assets/Beveiligingssystemen.jpg";
import HVAConderhoud from "../Assets/HVAConderhoud.jpg";
import TuinOnderhoud from "../Assets/TuinOnderhoud.jpg";
import DakenOnderhoud from "../Assets/DakenOnderhoud.jpg";
import AlgemeenOnderhoud from "../Assets/AlgemeenOnderhoud.jpg";
import KeukenVerbouwing from "../Assets/KeukenVerbouwing.jpg";
import BadkamerVerbouwing from "../Assets/BadkamerVerbouwing.jpg";
import VloerenLeggen from "../Assets/VloerenLeggen.jpg";
import Meubelmontage from "../Assets/Meubelmontage.jpg";
import Buitenassemblage from "../Assets/Buitenassemblage.jpg";
import fitnessapparatuur from "../Assets/fitnessapparatuur.jpg";
import Kantoorinrichtingen from "../Assets/Kantoorinrichtingen.jpg";
import SlimmeApparaten from "../Assets/SlimmeApparaten.jpg";
import Energiebeheer from "../Assets/Energiebeheer.jpg";
import ZonneEnergie from "../Assets/ZonneEnergie.jpg";
import {
  validateEmail,
  validateMessage,
  validateName,
  validatePhoneNumber,
} from "./inputValidators";

export const companyDetails = {
  name: "ZMAbouw",
  phoneNo: "06 229 52 377",
  phoneNoCall: "+316 229 52 377",
  email: "info@ZMAbouw.nl",
  address: `100 Elasmarstraat,
  1011 AA, Alkmaar`,
  regNo: "123456",
  regTypeName: "KvK",
};

export const pagesContent = [
  {
    name: "Home",
    path: "/home",
    sections: [
      { name: "Onze Diensten", id: "onze-diensten" },
      { name: "Ons Team", id: "ons-team" },
      { name: "Contact Forumlier", id: "contact-formulier" },
    ],
  },
  {
    name: "Onze Diensten",
    path: "/onze-diensten",
    sections: [
      { name: "Huisreparaties", id: "huisreparaties" },
      { name: "Installatie-diensten", id: "installatie-diensten" },
      { name: "Onderhouds-diensten", id: "onderhouds-diensten" },
      { name: "Renovatie en Verbouwing", id: "renovatie-en-verbouwing" },
      { name: "Montage-diensten", id: "montage-diensten" },
      {
        name: "Slimme en Duurzame Oplossingen",
        id: "slimme-en-duurzame-oplossingen",
      },
    ],
  },
  {
    name: "Over Ons",
    path: "/over-ons",
    sections: [
      { name: "Wie Wij Zijn", id: "wie-wij-zijn" },
      { name: "Onze Missie", id: "onze-missie" },
      { name: "Waarom Kiezen Voor Ons?", id: "waarom-kiezen-voor-ons" },
    ],
  },
  {
    name: "Contact",
    path: "/contact-ons",
    sections: [
      { name: "Telefoonnummer", id: "telefoonnummer", value: "06 229 52 377" },
      { name: "Email", id: "email", value: "info@ZMAbouw.nl" },
      {
        name: "Adres",
        id: "adres",
        value: "100 Elasmarstraat, 1011 AA, Alkmaar",
      },
      { name: "Contact Formulier", id: "contact-formulier" },
    ],
  },
];

// childPages: [
//   {
//     name: "Huisreparaties",
//     sections: [
//       "Loodgieterswerk",
//       "Elektrische reparaties",
//       "Timmerwerk",
//       "Dakreparaties",
//     ],
//   },
//   {
//     name: "Installatie-diensten",
//     sections: [
//       "Keukenapparatuur",
//       "Badkamerarmatuur",
//       "Verwarmingssystemen",
//       "Beveiligingssystemen",
//     ],
//   },
//   {
//     name: "Onderhouds-diensten",
//     sections: ["HVAC-onderhoud", "Tuin", "Daken", "Algemeen onderhoud"],
//   },
//   "Renovatie en Verbouwing",
//   "Montage-diensten",
//   "Slimme en Duurzame Oplossingen",
// ],

export const promoBulletPoints = [
  "24/7 bereikbaar voor al uw reparaties aan uw woning",
  "Gecertificeerde en ervaren vakmensen",
  "Transparante prijzen zonder verborgen kosten",
  "Tevredenheid gegarandeerd bij elke klus",
];

export const aboutUs = `Ons recent opgerichte bedrijf is gespecialiseerd in het leveren van hoogwaardige onderhouds- en verbeteringsdiensten voor woningen. We werken uitsluitend samen met de beste professionals om een breed scala aan diensten te bieden, van essentiële reparaties tot uitgebreide woningrenovaties. Elk project wordt uitgevoerd met een toewijding aan vakmanschap en aandacht voor detail, wat ons onderscheidt in de branche.

Door alleen samen te werken met de meest bekwame en ervaren partners, kunnen wij uitzonderlijke servicekwaliteit bieden tegen concurrerende prijzen. Dit maakt ons de voorkeurskeuze voor huiseigenaren die op zoek zijn naar betrouwbare en kosteneffectieve oplossingen voor hun huis. We begrijpen het belang van uw investering in uw woning en streven ernaar elke service zo in te richten dat deze aan uw specifieke behoeften en verwachtingen voldoet.

Als startende onderneming zijn we vastberaden om een betrouwbare reputatie op te bouwen en een sterke relatie met onze klanten te onderhouden. We zijn toegewijd aan transparantie, eerlijke prijsstelling en het bieden van een uitmuntende klantenservice. Kiezen voor ons betekent kiezen voor een partner die echt om uw huis geeft en die elke taak met de grootste zorg en professionaliteit aanpakt.
`;

export const servicesObject = [
  {
    name: "Huisreparaties",
    id: "huisreparaties",
    services: [
      {
        name: "Loodgieterswerk",
        id: "loodgieterswerk",
        bgImage: Loodgieterswerk,
        examples: [
          "Lekkende kranen repareren",
          "Verstopte afvoeren oplossen",
          "Vervangen van oude pijpleidingen",
          "Reparatie of installatie van radiatoren",
        ],
      },
      {
        name: "Elektrische reparaties",
        id: "elektrische-reparatie",
        bgImage: ElektrischeReparatie,
        examples: [
          "Stopcontacten vervangen",
          "Elektrische storingen oplossen",
          "Verlichtingssystemen installeren",
          "Installatie van energiezuinige systemen",
        ],
      },
      {
        name: "Timmerwerk",
        id: "timmerwerk",
        bgImage: Timmerwerk,
        examples: [
          "Deurscharnieren repareren",
          "Kapotte kozijnen herstellen",
          "Aangepaste houtconstructies",
          "Installatie van ingebouwde meubels",
        ],
      },
      {
        name: "Dakreparaties",
        id: "dakreparaties",
        bgImage: Dakreparaties,
        examples: [
          "Daktegels vervangen",
          "Lekken op het dak repareren",
          "Plafondisolatie verbeteren",
          "Onderhoud van goten en regenpijpen",
          "Installatie of reparatie van dakramen",
        ],
      },
    ],
  },
  {
    name: "Installatie-diensten",
    id: "installatie-diensten",
    services: [
      {
        name: "Keukenapparatuur",
        id: "keukenapparatuur",
        bgImage: Keukenapparatuur,
        examples: [
          "Vaatwassers installeren",
          "Ovens en kookplaten installeren",
          "Afzuigkappen monteren",
          "Koelkasten plaatsen",
        ],
      },
      {
        name: "Badkamerarmatuur",
        id: "badkamerarmatuur",
        bgImage: Badkamerarmatuur,
        examples: [
          "Douchecabines installeren",
          "Badkuipen plaatsen",
          "Toiletten installeren",
          "Wastafels monteren",
        ],
      },
      {
        name: "Verwarmingssystemen",
        id: "verwarmingssystemen",
        bgImage: Verwarmingssystemen,
        examples: [
          "CV-ketels installeren",
          "Radiatoren plaatsen",
          "Vloerverwarming leggen",
          "Thermostaten installeren",
        ],
      },
      {
        name: "Beveiligingssystemen",
        id: "beveiligingssystemen",
        bgImage: Beveiligingssystemen,
        examples: [
          "Alarmsystemen installeren",
          "Beveiligingscamera’s plaatsen",
          "Brandmelders monteren",
          "Slimme sloten installeren",
        ],
      },
    ],
  },
  {
    name: "Onderhouds-diensten",
    id: "onderhouds-diensten",
    services: [
      {
        name: "HVAC-onderhoud",
        id: "hvac-onderhoud",
        bgImage: HVAConderhoud,
        examples: [
          "Onderhouden van airconditioners",
          "Verwarmingssystemen controleren",
          "Vervangen van filters",
        ],
      },
      {
        name: "Tuin",
        id: "tuin",
        bgImage: TuinOnderhoud,
        examples: [
          "Grasmaaien",
          "Heggen snoeien",
          "Tuin schoonmaken",
          "Irrigatiesystemen onderhouden",
        ],
      },
      {
        name: "Daken",
        id: "daken",
        bgImage: DakenOnderhoud,
        examples: [
          "Dakgoten schoonmaken",
          "Afvoeren controleren",
          "Regenpijpen ontstoppen",
          "Goten inspecteren",
        ],
      },
      {
        name: "Algemeen onderhoud",
        id: "algemeen-onderhoud",
        bgImage: AlgemeenOnderhoud,
        examples: [
          "Muren schilderen",
          "Vloeren polijsten",
          "Raamsluitingen controleren",
          "Deurscharnieren smeren",
        ],
      },
    ],
  },
  {
    name: "Renovatie en Verbouwing",
    id: "renovatie-en-verbouwing",
    services: [
      {
        name: "Keuken verbouwing",
        id: "keuken-verbouwing",
        bgImage: KeukenVerbouwing,
        examples: [
          "Keukens ontwerpen",
          "Keukenkasten vervangen",
          "Werkbladen installeren",
          "Moderne keukenapparatuur integreren",
        ],
      },
      {
        name: "Badkamer verbouwing",
        id: "badkamer verbouwing",
        bgImage: BadkamerVerbouwing,
        examples: [
          "Badkamer ontwerpen",
          "Tegelwerk doen",
          "Sanitair moderniseren ",
          "Verlichting en accessoires installeren",
        ],
      },
      {
        name: "Woonruimten",
        id: "woonruimten",
        bgImage: VloerenLeggen,
        examples: [
          "Woonkamers herinrichten",
          "Slaapkamers opknappen",
          "Eetkamers moderniseren",
          "Vloeren leggen",
        ],
      },
    ],
  },
  {
    name: "Montage-diensten",
    id: "montage-diensten",
    services: [
      {
        name: "Meubelmontage",
        id: "meubelmontage",
        bgImage: Meubelmontage,
        examples: [
          "Assembleren van plat verpakte meubels",
          "Repareren van gebroken stoelpoten",
          "Vervangen van beschadigde meubelscharnieren",
          "Aanpassen van kastdeuren die niet goed sluiten",
        ],
      },
      {
        name: "Buitenassemblage",
        id: "buitenassemblage",
        bgImage: Buitenassemblage,
        examples: [
          "Tuinhuisjes opzetten",
          "Speeltoestellen monteren",
          "Schuttingen installeren",
          "Pergola's en terrasoverkappingen bouwen",
        ],
      },
      {
        name: "Sport- en fitnessapparatuur",
        id: "sport-en-fitnessapparatuur",
        bgImage: fitnessapparatuur,
        examples: [
          "Homegym montage",
          "Tafeltennistafels assembleren",
          "Basketbalstandaards opzetten",
          "Fitnessapparatuur monteren",
        ],
      },
      {
        name: "Kantoorinrichtingen",
        id: "kantoorinrichtingen",
        bgImage: Kantoorinrichtingen,
        examples: [
          "Bureaus en werkstations monteren",
          "Kantoorkasten assembleren",
          "Scheidingswanden plaatsen",
          "Ergonomische aanpassingen uitvoeren",
        ],
      },
    ],
  },
  {
    name: "Slimme en Duurzame Oplossingen",
    id: "slimme-en-duurzame-oplossingen",
    services: [
      {
        name: "Slimme apparaten",
        id: "slimme-apparaten",
        bgImage: SlimmeApparaten,
        examples: [
          "Slimme thermostaten installeren",
          "Slimme verlichtingssystemen integreren",
          "Slimme beveiligingscamera’s plaatsen",
          "Slimme rookmelders installeren",
        ],
      },
      {
        name: "Energiebeheer",
        id: "energiebeheer",
        bgImage: Energiebeheer,
        examples: [
          "Energie-monitoring systemen installeren",
          "Automatische lichtregelingssystemen opzetten",
          "Slimme meter integratie",
          "Thermostaat optimalisatie voor energiebesparing",
        ],
      },
      {
        name: "Zonne-energie systemen",
        id: "zonne-energie-systemen",
        bgImage: ZonneEnergie,
        examples: [
          "Zonnepanelen installeren",
          "Zonneboilers plaatsen",
          "Onderhoud van bestaande zonnepaneelsystemen",
          "Integratie van zonne-energie met huisnetwerken",
        ],
      },
    ],
  },
];

export const contactFormData = [
  {
    name: "voornaam",
    label: "Voornaam",
    value: "",
    required: true,
    validator: validateName,
    errors: null,
  },
  {
    name: "achternaam",
    label: "Achternaam",
    value: "",
    required: true,
    validator: validateName,
    errors: null,
  },
  {
    name: "email",
    label: "E-mail",
    type: "email",
    value: "",
    required: true,
    validator: validateEmail,
    errors: null,
  },
  {
    name: "telefoonnummer",
    label: "Telefoonnummer",
    value: "",
    type: "tel",
    required: true,
    validator: validatePhoneNumber,
    errors: null,
  },
  {
    name: "bericht",
    label: "Uw Bericht of Vraag",
    minLength: 0,
    maxLength: 200,
    placeholder: "Typ hier uw bericht of vraag...",
    value: "",
    required: true,
    validator: validateMessage,
    errors: null,
  },
  {
    name: "contactVoorkeur",
    label: "Voorkeur contactmethode:",
    value: "geen voorkeur",
    options: ["geen voorkeur", "email", "telefonish"],
  },
];

export const offerteFormData = [
  ...contactFormData,
  {
    name: "serviceCategory",
    label: "service categorie",
    placeholder: "Kies een service category",
    value: "",
    required: true,
    options: servicesObject.map((x) => x.name),
    errors: null,
  },
];

export const validateName = (name) => {
  // Check if the name is not empty
  if (!name.trim()) {
    return "Naam is vereist.";
  }

  // Check if the name contains only letters (and optionally spaces, apostrophes, or hyphens)
  if (!/^[a-zA-Z\s'-]+$/.test(name)) {
    return "Naam mag alleen letters bevatten.";
  }

  // If no issues, return null or an empty string indicating no error
  return false;
};

export const validateEmail = (email, required = false) => {
  // Check if the field is not required and the value is empty
  if (!required && !email.trim()) {
    return null; // No error if not required and empty
  }

  // Check if required and empty
  if (required && !email.trim()) {
    return "E-mail is verplicht."; // Email is required but empty
  }

  // Regex pattern for validating email
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  // Test the email against the regex
  if (regex.test(email)) {
    return false; // No error, valid email
  } else {
    return "Voer een geldig e-mailadres in.";
  }
};

export const validatePhoneNumber = (phoneNumber, required = false) => {
  // Check if the field is not required and the value is empty
  if (!required && !phoneNumber.trim()) {
    return null; // No error if not required and empty
  }

  // Check if required and empty
  if (required && !phoneNumber.trim()) {
    return "Telefoonnummer is verplicht.";
  }

  // Normalize by removing spaces, hyphens, and parentheses
  const normalizedNumber = phoneNumber.replace(/[\s-()]/g, "");

  // Regex to check if it is a valid Dutch phone number
  const regex = /^(?:\+31|0031|0)(6\d{8}|[1-9]\d{8})$/;

  // Test the normalized number against the regex
  if (phoneNumber && regex.test(normalizedNumber)) {
    return false;
  } else {
    return "Voer een geldig telefoonnummer in.";
  }
};

export const validateMessage = (input, maxLength) => {
  // Check if the input is not empty
  if (!input.trim()) {
    return "The message cannot be empty.";
  }

  // Check if the input exceeds the maximum length allowed
  if (input.length > maxLength) {
    return `The message cannot exceed ${maxLength} characters.`;
  }

  // If no issues, return false indicating no error
  return false;
};

export const validateForm = (formData) => {
  return formData.filter(({ required, value, errors }) => {
    // If there are errors but the field isn't required and has no value, ignore the errors
    if (!required && !value && errors) {
      return false;
    }

    // Check if there are any errors, or if the field is required and empty
    return (required && !value) || errors;
  });
};

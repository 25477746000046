import React from "react";
import {
  AbsTxtSpan,
  DynamicLabel,
  FlexContainer,
  Input,
  IsRequiredStar,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";

const TextInput = ({
  width,
  mWidth,
  height,
  mHeight,
  properties,
  setForm,
  index,
}) => {
  const {
    label,
    type,
    required,
    value,
    validator,
    minLength,
    maxLength,
    errors,
    disabled,
  } = properties ?? {};

  // console.log('properties',index,properties.label)

  const runValidation = (e) => {
    const { value } = e.target;
    if (typeof validator === "function") {
      setForm((prev) => {
        const formData = [...prev];
        formData[index].errors = validator(
          value,
          required,
          minLength,
          maxLength
        );
        return formData;
      });
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setForm((prev) => {
      const formData = [...prev];
      formData[index].value = value;
      return formData;
    });
    if (errors !== null) {
      runValidation(e);
    }
  };

  return (
    <FlexContainer
      $width={width}
      $mWidth={mWidth}
      $height={height}
      $mHeight={mHeight}
    >
      <Input
        $border={`1px solid ${errors ? "red" : theme.secColor}`}
        type={type}
        $width="100%"
        $mFSize="2.25vh"
        $padding="0 1vh"
        $bRadius="6px"
        required={required}
        disabled={disabled}
        value={value}
        style={{ zIndex: "1" }}
        onChange={handleChange}
        onBlur={runValidation}
      />
      {value ? (
        <DynamicLabel
          $position="absolute"
          $height="1.5vh"
          $top="-1vh"
          $left="1vh"
          $fSize="1.75vh"
          // $mFSize="2vh"
          $zIndex="1"
          $bgColor="white"
        >
          {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      ) : (
        <DynamicLabel
          $position="absolute"
          $height="95%"
          $fSize="1.75vh"
          $mFSize="2vh"
          $padding="0 1vh"
        >
          {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      )}
      {errors ? (
        <AbsTxtSpan
          $color="red"
          $fSize="1.25vh"
          $mFSize="1.75vh"
          $height="1.5vh"
          $bottom="-1.9vh"
        >
          {errors}
        </AbsTxtSpan>
      ) : null}
    </FlexContainer>
  );
};

export default TextInput;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import { FlexContainer } from "./Styles/Global.Styled";
import OnzeDiensten from "./Pages/OnzeDiensten";
import OverOns from "./Pages/OverOns";
import ContactOns from "./Pages/ContactOns";
import DienstPage from "./Pages/DienstPage";
import ScrollToTop from "./helpers/ScrollToTop";
import { useEffect, useState } from "react";
import OffertePage from "./Pages/OffertePage";
import NavBar from "./Components/Navbar/NavBar";
import Footer from "./Components/Footer/Footer";
import MobileNavBar from "./Components/Navbar/MobileNavBar";

function App() {
  const [popUp, setPopUp] = useState(false);

  useEffect(() => {
    document.body.style.overflow = popUp ? "hidden" : "auto";
  }, [popUp]);

  return (
    <Router>
      <ScrollToTop />
      {popUp && <MobileNavBar setPopUp={setPopUp} />}
      <FlexContainer $flexD="column" $mWidth="100%" >
        <NavBar setPopUp={setPopUp} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/onze-diensten" element={<OnzeDiensten />} />
          <Route path="/onze-diensten/:serviceName" element={<DienstPage />} />
          <Route path="/over-ons" element={<OverOns />} />
          <Route path="/contact-ons" element={<ContactOns />} />
          <Route path="/offerte-aanvragen" element={<OffertePage />} />
        </Routes>
        <Footer />
      </FlexContainer>
    </Router>
  );
}

export default App;

import React from "react";
import {
  Dot,
  FlexContainer,
  FlexH4,
  FlexImg,
  H1,
} from "../Styles/Global.Styled";
import PageHeader from "../Components/PageTitle/PageHeader";
import { useParams } from "react-router-dom";
import { servicesObject } from "../helpers/data";

const DienstPage = () => {
  let { serviceName } = useParams();
  let service = servicesObject.find((x) => {
    console.log(serviceName);
    return x.id === serviceName;
  });
  
  return (
    <FlexContainer $flexD="column">
      <PageHeader title={service?.name} />
      {service?.services.map((item, index) => (
        <FlexContainer
          key={index}
          id={item.id}
          $flexD={index % 2 === 0 ? "row" : "row-reverse"}
          $mFlexD="column"
          $bgColor={index % 2 === 0 ? null : "main"}
          $padding="7vh 20vh"
          $mPadding="6vh 2vh"
          $mGap="4vh"
          $justifyC="space-evenly"
        >
          <FlexContainer $flexD="column" $gap="2vh" $mGap=".5vh">
            <H1 $color="secColor" $fSize="7vh" $mFSize="3.75vh">{item.name}</H1>
            <FlexContainer $flexD="column" $gap=".5vh">
              {item.examples.map((example) => (
                <FlexH4 $align="center" key={example} $fSize="clamp(1.5vh, 1.75vw, 2vh)">
                  <Dot /> {example}
                </FlexH4>
              ))}
            </FlexContainer>
          </FlexContainer>
          <FlexImg
            src={item.bgImage}
            $height="35vh"
            $mHeight="25vh"
            $bRadius="6px"
            // $objectFit="cover"
          />
        </FlexContainer>
      ))}
    </FlexContainer>
  );
};

export default DienstPage;

import React from "react";
import {
  FlexContainer,
  FlexImg,
  H1,
  Paragraph,
} from "../../Styles/Global.Styled";
import PaintersTeam from "../../Assets/PaintersTeam.jpg";
import { aboutUs } from "../../helpers/data";

const OnsTeam = () => {
  return (
    <FlexContainer
      id="ons-team"
      $padding="5vh 20vh"
      $mPadding="5vh 2vh"
      $bgColor="main"
      $gap="4vh"
      $align="center"
      $mFlexD="column-reverse"
    >
      <FlexImg
        src={PaintersTeam}
        $bRadius="12px"
        $height="40vh"
        $mHeight="100%"
        $mWidth="100%"
        $objectFit="fill"
      />
      <FlexContainer $flexD="column">
        <H1 $fSize="5vh" $color="secColor">
          Over ons
        </H1>
        <Paragraph $fSize="1.75vh" style={{ whiteSpace: "pre-line" }}>
          {aboutUs}
        </Paragraph>
      </FlexContainer>
    </FlexContainer>
  );
};

export default OnsTeam;

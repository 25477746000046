import React, { useCallback, useEffect } from "react";
import {
  CustomButton,
  FlexContainer,
  TxtSpan,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";

const OptionsInput = ({ properties, setForm, index, extraHandle }) => {
  const { options, value, label } = properties;

  const handleChange = useCallback(
    (value) => {
      setForm((prev) => {
        const formData = [...prev];
        formData[index].value = value;
        return formData;
      });
      extraHandle(value, setForm);
    },
    [setForm, index, extraHandle]
  );

  // Effect to check and set the initial value if it is empty
  useEffect(() => {
    if (value === "" && options.length > 0) {
      handleChange(options[0]);
    }
    // Ensure to add dependencies to only run this when necessary
  }, [value, options, handleChange]);

  return (
    <FlexContainer $gap="2vh" $mFlexD="column">
      <TxtSpan>{label}</TxtSpan>
      <FlexContainer $gap="2vh">
        {options.map((option) => (
          <CustomButton
            key={option}
            type="button"
            $justifyC="flex-start"
            $mFSize="4vw"
            $mGap="1.5vw"
            onClick={() => handleChange(option)}
            $gap=".5vh"
            style={{ whiteSpace: "nowrap" }}
          >
            <FlexContainer
              $border={`1px solid ${theme.secColor}`}
              $bRadius="50%"
              $width="3vh"
              $height="3vh"
              $align="center"
              $justifyC="center"
            >
              <FlexContainer
                $bRadius="50%"
                $width="1.5vh"
                $height="1.5vh"
                $bgColor={value === option ? "main" : null}
              />
            </FlexContainer>
            {option}
          </CustomButton>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default OptionsInput;

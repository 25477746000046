import React from "react";

const PhoneIcon = ({style, color}) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 13 13"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.454 8.48174L9.73459 8.28544C9.53242 8.26169 9.32749 8.28407 9.13521 8.3509C8.94294 8.41773 8.7683 8.52727 8.62444 8.67128L7.3789 9.91682C5.45725 8.93949 3.89531 7.37756 2.91799 5.4559L4.17029 4.2036C4.46137 3.91252 4.60352 3.50637 4.55614 3.09345L4.35983 1.38761C4.32145 1.05739 4.16298 0.752813 3.91458 0.531873C3.66617 0.310933 3.3452 0.189059 3.01276 0.189454H1.84168C1.07676 0.189454 0.440454 0.82576 0.487838 1.59068C0.846607 7.37159 5.46998 11.9882 11.2441 12.347C12.009 12.3944 12.6454 11.758 12.6454 10.9931V9.82205C12.6521 9.13836 12.1377 8.56297 11.454 8.48174Z"
        fill={color? color : "#5A5A5A"}
      />
    </svg>
  );
};

export default PhoneIcon;

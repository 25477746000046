import React from 'react'
import OfferteForm from '../Forms/OfferteForm'
import PageHeader from '../Components/PageTitle/PageHeader'
import { FlexContainer } from '../Styles/Global.Styled'

const OffertePage = () => {
  return (
    <FlexContainer $width="100%" $flexD="column">
        <PageHeader title="Offerte Formulier" />
        <OfferteForm/>
    </FlexContainer>
  )
}

export default OffertePage
import React from "react";

const LocationIcon = ({ style, color }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 17"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15412 1.52199C5.77849 1.52199 4.4592 2.06845 3.48648 3.04117C2.51377 4.01389 1.9673 5.33318 1.9673 6.70881C1.9673 8.82947 3.28772 10.8753 4.73706 12.4602C5.47854 13.2672 6.28712 14.0099 7.15412 14.6802C8.0211 14.0104 8.82968 13.2682 9.57118 12.4617C11.0205 10.8753 12.3409 8.83021 12.3409 6.70881C12.3409 5.33318 11.7945 4.01389 10.8218 3.04117C9.84904 2.06845 8.52975 1.52199 7.15412 1.52199ZM7.15412 16.5L6.73399 16.2111L6.73176 16.2096L6.72732 16.2059L6.7125 16.1955L6.65693 16.1562C6.35011 15.9332 6.05013 15.7009 5.75738 15.4597C5.00705 14.8433 4.30067 14.1753 3.64338 13.4606C2.12883 11.8037 0.485352 9.40373 0.485352 6.70881C0.485352 4.94014 1.18795 3.24391 2.43859 1.99328C3.68922 0.742639 5.38545 0.0400391 7.15412 0.0400391C8.92279 0.0400391 10.619 0.742639 11.8696 1.99328C13.1203 3.24391 13.8229 4.94014 13.8229 6.70881C13.8229 9.40373 12.1794 11.8045 10.6649 13.4606C9.80671 14.3939 8.86523 15.2471 7.85212 16.0095C7.7677 16.0726 7.68248 16.1346 7.59648 16.1955L7.58092 16.2059L7.57647 16.2096L7.57499 16.2103L7.15412 16.5ZM7.15412 5.22686C6.76108 5.22686 6.38414 5.38299 6.10622 5.66091C5.8283 5.93883 5.67217 6.31577 5.67217 6.70881C5.67217 7.10184 5.8283 7.47878 6.10622 7.7567C6.38414 8.03462 6.76108 8.19075 7.15412 8.19075C7.54716 8.19075 7.9241 8.03462 8.20201 7.7567C8.47993 7.47878 8.63607 7.10184 8.63607 6.70881C8.63607 6.31577 8.47993 5.93883 8.20201 5.66091C7.9241 5.38299 7.54716 5.22686 7.15412 5.22686ZM4.19022 6.70881C4.19022 5.92273 4.50249 5.16885 5.05833 4.61301C5.61417 4.05718 6.36804 3.74491 7.15412 3.74491C7.94019 3.74491 8.69407 4.05718 9.24991 4.61301C9.80575 5.16885 10.118 5.92273 10.118 6.70881C10.118 7.49488 9.80575 8.24876 9.24991 8.8046C8.69407 9.36044 7.94019 9.6727 7.15412 9.6727C6.36804 9.6727 5.61417 9.36044 5.05833 8.8046C4.50249 8.24876 4.19022 7.49488 4.19022 6.70881Z"
        fill={color ? color : "#5A5A5A"}
      />
    </svg>
  );
};

export default LocationIcon;

import React from "react";
import {
  Dot,
  FlexContainer,
  FlexH4,
  FlexImg,
  // H1,
  H2,
  TxtSpan,
} from "../../Styles/Global.Styled";
import landingPromo from "../../Assets/landingPromo.jpg";
import { promoBulletPoints } from "../../helpers/data";
import CallButton from "../../Components/Buttons/CallButton";
import OfferteButton from "../../Components/Buttons/OfferteButton";

const LandingPromo = () => {
  return (
    <FlexContainer $width="100%" $justifyC="center" >
      {/* Background Picture */}
      <FlexImg
        src={landingPromo}
        $objectFit="fill"
        $width="100%"
        $height="100%"
        $position="absolute"
        style={{
          filter: "opacity(75%) brightness(25%)",
          zIndex: "1",
        }}
      />
      {/* Content */}
      <FlexContainer
        style={{ zIndex: "2" }}
        $flexD="column"
        $align="center"
        $padding="7vh 0"
        $mPadding="5vh 1vh"
      >
        {/* Main header */}
        {/* <H1 $fSize="4vh" $mFSize="3.25vh" $color="main" $txtAlign="center">
          Professionele klusjesdienst bij u thuis
        </H1> */}
        {/* Sub header */}
        {/* <FlexH4
          $fSize="2vh"
          $mFSize="1.5vh"
          $bgColor="secColor"
          $color="main"
          $bRadius="6px"
          $padding=".1vh 1vh"
          style={{ textAlign: "start", fontStyle:'italic' }}
        >
          Snelle en betrouwbare oplossingen voor elk huis
        </FlexH4> */}
        {/* Call to action headers */}
        <FlexContainer
          $margin="0 0 3vh 0"
          $gap=".5vh"
          $diplay="flex"
          $flexD="column"
          $align="center"
        >
          <H2 $fSize="3.25vh" $mFSize="2.75vh" $txtAlign="center">
            Bel ons nu! en plan uw service
          </H2>
          <TxtSpan
            $font="fontThrid"
            $fSize="2.75vh"
            $mFSize="1.75vh"
            $color="main"
            $bgColor="secColor"
            $padding=".5vh 1vh"
            $bRadius="12px"
            $fWeight="600"
            style={{ fontStyle: "italic" }}
          >
            Of
          </TxtSpan>
          <H2 $fSize="3.25vh" $mFSize="2.75vh" $txtAlign="center">
            Vraag nu een gratis offerte aan!
          </H2>
        </FlexContainer>
        {/* Call to action buttons */}
        <FlexContainer $gap="2vh">
          <CallButton/>
          <OfferteButton/>
        </FlexContainer>
        {/* Promo bullet points */}
        <FlexContainer
          $padding="1vh"
          $gap=".5vh"
          $flexD="column"
          $bRadius="6px"
          $bgColor="secColor"
          $margin="5vh 0 0 0"
        >
          {promoBulletPoints.map((bulletPoint, index) => (
            <FlexH4
              key={index}
              $align="center"
              style={{ textAlign: "start" }}
              $fSize="2vh"
              $mFSize="1.5vh"
              $color="main"
            >
              <FlexContainer $mHeight="100%" $mPadding=".5vh 0 0 0">
                <Dot $bgColor="main" $width="1vh" $height="1vh" />
              </FlexContainer>
              {bulletPoint}
            </FlexH4>
          ))}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default LandingPromo;

import styled, { keyframes } from "styled-components";
import { Dot } from "./Global.Styled";

const waveAnimation = keyframes`
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-6px);
  }
`;

export const LoadingDot = styled(Dot)`
  /* background-color:${({ theme }) => theme.bgColor}; */
  /* Animation */
  animation: ${waveAnimation} 1s ease-in-out infinite;
  animation-delay: ${(props) => props.$delay};
`;
const spinnerKeyFrames = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
export const Spinner = styled.div`
  display: inline-block;
  box-sizing: border-box;
  height: ${({ $height }) => $height || "4vh"};
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 6px solid ${({ theme }) => theme.secColor};
  border-bottom-color: ${({ theme, $bgColor }) =>
    theme[$bgColor] || theme.secShadow};
  animation: ${spinnerKeyFrames} 1.2s linear infinite;
`;

export const FlashError = keyframes`
0%{
  transform: scale(1.01);
};
25%{
  transform: scale(1);
};
50%{
  transform: scale(1.02);
};
75%{
  transform: scale(1);
};
100%{
  transform: scale(1.04);
};
`;

export const theme = {
  main: "#fec425",
  mainShadow: "rgba(254, 196, 37,0.5)",
  white: "white",
  black: "#000000",
  adBg: "#849a72",
  secColor: "#4572ad",
  secShadow: "rgba(69, 114, 173,0.5)",
  thirdColor: "#2e2c2f",
  sliderShadow: "rgba(217, 217, 217, .5)",
  red: "red",
  green: "#4BB543",
  shadowColor: "rgba(0,0,0, .5)",
  fontMain: "Poppins",
  fontSec: "Playfair Display",
  fontThrid: "Noto Serif",
  defaultFont: "sans-serif",
};

export const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "414px",
  tablet: "768px",
  laptop: "992px",
  laptopL: "1200px",
  desktop: "1440px",
  desktopL: "1680px",
  desktopXL: "1920px",
};

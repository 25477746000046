import React from "react";
import { FlexContainer } from "../Styles/Global.Styled";
import LandingPromo from "./Home/LandingPromo";
import Diensten from "./Home/Diensten";
import OnsTeam from "./Home/OnsTeam";
import ContactForm from "../Forms/ContactForm";

const Home = () => {
  return (
    <FlexContainer $width="100%" $flexD="column">
      <LandingPromo id="landingPromo" />
      <Diensten />
      <OnsTeam />
      <ContactForm id="contact-formulier" />
    </FlexContainer>
  );
};

export default Home;

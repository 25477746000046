import React from "react";
import { ToastContainer } from "./Toast.styled";
// import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import {
  CustomButton,
  FlexContainer,
  FlexH4,
  TxtSpan,
} from "../../Styles/Global.Styled";

export const Toast = ({
  type,
  title,
  message = "Unkown error",
  setToast,
  hidden,
  position,
  top,
  width,
  height,
}) => {
  return (
    <ToastContainer
      $type={type}
      $hidden={hidden}
      $top={top}
      $position={position}
      $width={width}
      $height={height}
      $justifyC="space-between"
      $bRadius="0 0 12px 12px"
      $padding="0 2vh"
      $mPadding="0 1vh"
      $gap="1vh"
    >
      <FlexContainer $flexG="1" $gap="1vh">
        <FlexH4 $fSize="1.75vh" $mFSize="1.5vh" $color="white">
          {title}
        </FlexH4>
        <TxtSpan $fSize="1.5vh" $color="white">
          {message}
        </TxtSpan>
      </FlexContainer>
      <CustomButton
        $color="white"
        $height="3vh"
        $width="3vh"
        $fSize="3vh"
        $font="fontThrid"
        $fWeight="600"
        $margin="-.5vh 0 0 0"
        onClick={() => setToast({ hidden: true })}
      >
        x
      </CustomButton>
    </ToastContainer>
  );
};

import React from "react";
import { FlexContainer } from "../Styles/Global.Styled";
import PageHeader from "../Components/PageTitle/PageHeader";
import Diensten from "../Pages/Home/Diensten"

const OnzeDiensten = () => {
  return (
    <FlexContainer $width="100%" $align="center" $flexD="column">
      <PageHeader title="Onze diensten" />
      <Diensten hideTitle={true} allowChangeFocus={true}/>
    </FlexContainer>
  );
};

export default OnzeDiensten;

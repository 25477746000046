import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  FlexContainer,
  Paragraph,
} from "../../Styles/Global.Styled";
import Logo from "../../Components/Logo";
import { pagesContent } from "../../helpers/data";
import useNavigateAndScroll from "../../Hooks/useNavigateAndScroll";

const Footer = () => {
  const navigate = useNavigate();
  const navigateAndScroll = useNavigateAndScroll();
  return (
    <FlexContainer
      $bgColor="thirdColor"
      $padding="5vh 10vh"
      $mPadding="5vh 2vh"
      $mFlxWrap="wrap"
      $gap="3vh"
    >
      <FlexContainer $gap="1vh" $width="25%" $mWidth="auto" $flexD="column">
        <Logo height="10" />
        <Paragraph $color="white" $fSize="1.5vh">
          Bij ZMAbouw zetten we ons in om eersteklas klusjesmannendiensten te
          leveren die elke behoefte aan huis- en kantooronderhoud dekken.
        </Paragraph>
      </FlexContainer>
      <FlexContainer $mFlxWrap="wrap" $mGap="3vh">
        {pagesContent.map((page) => (
          <FlexContainer
            key={page.name}
            $padding="0 3vh"
            $mPadding="0"
            $gap="1vh"
            $flexD="column"
          >
            <CustomButton
              $color="white"
              $padding="0 1vh"
              // $border="2px solid red"
              $fSize="1.75vh"
              $margin="0 0 1vh 0"
              $fWeight="600"
              $justifyC="flex-start"
              onClick={() => navigate(page.path)}
            >
              {page.name}
            </CustomButton>
            <FlexContainer $flexD="column" $gap="1.5vh">
              {page.sections.map((section, index) => (
                <CustomButton
                  key={index}
                  // $border="2px solid red"
                  $color="white"
                  $padding="0 1vh"
                  $fSize="1.75vh"
                  $mFSize={section.name.length > 25 ? "1.25vh" : "1.5vh"}
                  $justifyC="flex-start"
                  style={{ whiteSpace: "pre-line" }}
                  onClick={() => {
                    page.name === "Onze Diensten"
                      ? navigate(`${page.path + "/" + section.id}`)
                      : navigateAndScroll(page.path, section.id);
                  }}
                >
                  {section.name}
                </CustomButton>
              ))}
            </FlexContainer>
          </FlexContainer>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Footer;

import { useState, useEffect } from "react";

const useScrollDirection = (threshold = 5) => {
  const [direction, setDirection] = useState(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  // const threshold = 5; // Minimum scroll difference to detect direction

  useEffect(() => {
    const updateDirection = () => {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;

      // Ignore minor negative scroll due to iOS bounce effect
      if (scrollY < 0) return;

      // Calculate direction only when the threshold is exceeded
      if (Math.abs(scrollY - lastScrollTop) > threshold) {
        setDirection(scrollY > lastScrollTop ? "down" : "up");
        setLastScrollTop(scrollY);
      }
    };

    window.addEventListener("scroll", updateDirection);
    return () => {
      window.removeEventListener("scroll", updateDirection);
    };
  }, [lastScrollTop, threshold]);

  return direction;
};

export default useScrollDirection;
import React, { useEffect, useRef, useState } from "react";
import { CustomButton, FlexContainer, Form, H1 } from "../Styles/Global.Styled";
import { offerteFormData } from "../helpers/data";
import TextInput from "../Components/Inputs/TextInput";
import TextAreaInput from "../Components/Inputs/TextAreaInput";
import { validateForm } from "../helpers/inputValidators";
import { Toast } from "../Components/Toast/Toast";
import { requestQuote } from "../api/userRequest";
import { cleanUpFormValue, convertContactForm } from "../helpers/functions";
import OptionsInput from "../Components/Inputs/OptionsInput";
import SelectInput from "../Components/Inputs/SelectInput";
import { Spinner } from "../Styles/Animated.Styled";
import FormMessage from "./FormMessage";

const OfferteForm = () => {
  const formRef = useRef(null);
  const [formOpen, setFormOpen] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [formData, setForm] = useState([...offerteFormData]);
  const [toast, setToast] = useState({
    hidden: true,
    text: "",
    type: "",
  });

  useEffect(() => {
    let myTimeout;
    if (!toast.hidden) {
      myTimeout = setTimeout(() => {
        setToast({ ...toast, hidden: true });
      }, 10000);
    }
    return () => clearTimeout(myTimeout);
  }, [toast, setToast]);

  const handleOptions = (selectedOption, setForm) => {
    if (selectedOption === "geen voorkeur") {
      return setForm((prev) => {
        const formData = [...prev];
        const updatedFormData = formData.map((item) => {
          if (item.name === "email" || item.name === "telefoonnummer") {
            item.required = true;
            return item;
          }
          return item;
        });
        return updatedFormData;
      });
    }
    if (selectedOption === "email") {
      return setForm((prev) => {
        const formData = [...prev];
        const updatedFormData = formData.map((item) => {
          if (item.name === "email") {
            item.required = true;
            return item;
          }
          if (item.name === "telefoonnummer") {
            item.required = false;
            if (!item.value && item.errors) {
              item.errors = false;
            }
            return item;
          }
          return item;
        });
        return updatedFormData;
      });
    }
    if (selectedOption === "telefonish") {
      return setForm((prev) => {
        const formData = [...prev];
        const updatedFormData = formData.map((item) => {
          if (item.name === "telefoonnummer") {
            item.required = true;
            return item;
          }
          if (item.name === "email") {
            item.required = false;
            if (!item.value && item.errors) {
              item.errors = false;
            }
            return item;
          }
          return item;
        });
        console.log("updatedFormData", updatedFormData);
        return updatedFormData;
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formErrors = validateForm(formData);
    console.log("formErrors", formErrors);
    if (formErrors.length > 0) {
      setLoading(false);
      setToast({
        title: "Ongeldige invoerfout:",
        text: `${formErrors[0].errors || formErrors[0].label + " is verplict"}`,
        type: "Error",
        hidden: false,
      });
      window.scrollTo({
        top: 170,
        left: 0,
        behavior: "smooth",
      });
      return;
    }
    const convertedForm = convertContactForm(formData);
    console.log("convertedForm", convertedForm);
    const res = await requestQuote(convertedForm).catch((err) => err);
    console.log("res", res);
    setLoading(false);
    if (res.status === 200) {
      setForm(cleanUpFormValue(offerteFormData))
      return setFormOpen(false);
    }
    setToast({
      title: "Onverwact fout",
      text: "Probeer het nog nogmaals",
      type: "Error",
      hidden: false,
    });
    window.scrollTo({
      top: 170,
      left: 0,
      behavior: "smooth",
    });
    return;
  };

  if (formOpen) {
    return (
      <Form
        ref={formRef}
        id="offerte-formulier"
        onSubmit={(e) => submitForm(e)}
        $padding="5vh"
        $mPadding="5vh 1vh"
        $flexD="column"
        $align="center"
        $gap="5vh"
        $mWidth="100%"
      >
        {!toast.hidden && (
          <Toast
            top="0"
            message={toast.text}
            title={toast.title}
            type={toast.type}
            setToast={setToast}
            position={"absolute"}
            height={"5vh"}
          />
        )}
        <H1 $fSize="4vh" $mFSize="3.5vh" $margin="0 0 1vh 0" $mMargin="0">
          Offerte aanvragen
        </H1>
        <SelectInput
          width="35vh"
          mWidth="35vh"
          height="5vh"
          mHeight="6vh"
          index={6}
          properties={formData[6]}
          setForm={setForm}
        />
        {/* Voornaam en achternaam */}
        <FlexContainer $gap="3vh" $mGap="5vh" $mFlexD="column">
          <TextInput
            width="30vh"
            mWidth="35vh"
            height="5vh"
            mHeight="6vh"
            index={0}
            properties={formData[0]}
            setForm={setForm}
          />
          <TextInput
            width="30vh"
            mWidth="35vh"
            height="5vh"
            mHeight="6vh"
            index={1}
            properties={formData[1]}
            setForm={setForm}
          />
        </FlexContainer>
        {/* Contact methode opties */}
        <OptionsInput
          index={5}
          properties={formData[5]}
          setForm={setForm}
          extraHandle={handleOptions}
        />
        {/* Email */}
        <TextInput
          width="45vh"
          mWidth="40vh"
          height="5vh"
          mHeight="6vh"
          index={2}
          properties={formData[2]}
          setForm={setForm}
        />
        {/* Telefoonnummer */}
        <TextInput
          width="45vh"
          mWidth="40vh"
          height="5vh"
          mHeight="6vh"
          index={3}
          properties={formData[3]}
          setForm={setForm}
        />
        {/* Bericht */}
        <TextAreaInput
          width="45vh"
          mWidth="40vh"
          height="15vh"
          mHeight="20vh"
          index={4}
          properties={formData[4]}
          setForm={setForm}
        />
        {/* Submit button */}
        <CustomButton
          $padding="1.5vh 5vh"
          $margin="1vh 0 0 0"
          $mMargin="0"
          $width="25vh"
          $fSize="2.5vh"
          $fWeight="600"
          $bRadius="9px"
          $bgColor="main"
          $color="secColor"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : "Verzenden"}
        </CustomButton>
      </Form>
    );
  }
  if (!formOpen) {
    return (
      <FlexContainer
        $height="80vh"
        $mPadding="0 2vh"
        $width="100%"
        $flexD="column"
        $align="center"
        $justifyC="center"
        // $border="2px solid"
      >
        <FormMessage
          width="10vh"
          height="10vh"
          title="Bedankt voor uw aanvraag!"
          message="Wij hebben uw verzoek ontvangen en nemen zo snel mogelijk contact met u
op"
        />
        <CustomButton
          $fSize="2vh"
          style={{ textDecoration: "underline" }}
          $color="secColor"
          $margin="1vh 0 0 0"
          onClick={()=>setFormOpen(true)}
        >
          Een nieuw bericht sturen
        </CustomButton>
      </FlexContainer>
    );
  }
};

export default OfferteForm;

import React from "react";
import {
  FlexContainer,
  H1,
  H2,
  H3,
  Line,
  TxtSpan,
} from "../Styles/Global.Styled";
import PageHeader from "../Components/PageTitle/PageHeader";
import ContactForm from "../Forms/ContactForm";
import EnvelopeIcon from "../Icons/EnvelopeIcon";
import LocationIcon from "../Icons/LocationIcon";
import PhoneIcon from "../Icons/PhoneIcon";
import { theme } from "../Styles/theme";
import { companyDetails} from "../helpers/data";

const ContactOns = () => {
  return (
    <FlexContainer $width="100%" $flexD="column">
      <PageHeader title="Contact ons" />
      <FlexContainer
        $padding="5vh 20vh"
        $mPadding="2vh"
        $justifyC="center"
        $mFlexD="column"
      >
        {/* Contact details section */}
        <FlexContainer
          $width="50%"
          $mWidth="auto"
          $padding="0 5vh"
          $mPadding="0"
          $height="max-content"
          $justifyC="center"
          style={{ flexWrap: "wrap", rowGap: "5vh" }}
          $gap="5%"
          $mFlexD="column"
          $mAlign="center"
        >
          <H1 $fSize="3vh" style={{ width: "100%" }}>
            Onze contactgegevens:
          </H1>
          {/* phone number */}
          <FlexContainer
            id="telefoonnummer"
            $flexD="column"
            $align="center"
            $bRadius="9px"
            $padding="1vh"
            $bgColor="main"
            // $border="2px solid red"
            $width="45%"
            $mWidth="75%"
          >
            <PhoneIcon
              color={`${theme.secColor}`}
              style={{ height: "4vh", width: "4vh" }}
            />
            <H3 $color="secColor">Telefoonnummer</H3>
            <H2 $color="secColor">{companyDetails.phoneNo}</H2>
          </FlexContainer>
          {/* email */}
          <FlexContainer
            $flexD="column"
            $align="center"
            $bRadius="9px"
            $padding="1vh"
            $bgColor="main"
            // $width="45%"
            id="email"
            $mWidth="75%"
          >
            <EnvelopeIcon
              color={`${theme.secColor}`}
              style={{ height: "4vh", width: "4vh" }}
            />
            <H3 $color="secColor">Email</H3>
            <H2 $color="secColor">{companyDetails.email}</H2>
          </FlexContainer>
          {/* Address */}
          <FlexContainer
            $flexD="column"
            $align="center"
            $bRadius="9px"
            $padding="1vh"
            $bgColor="main"
            $width="45%"
            $mWidth="75%"
            id="adres"
          >
            <LocationIcon
              color={`${theme.secColor}`}
              style={{ height: "5vh", width: "5vh" }}
            />
            <H2 $color="secColor">Address</H2>
            <TxtSpan
              $color="secColor"
              $fSize="2vh"
              style={{ whiteSpace: "pre-line", textAlign: "center" }}
            >
              {companyDetails.address}
            </TxtSpan>
          </FlexContainer>
        </FlexContainer>
        {/* Line splitting both section */}
        <FlexContainer
          $flexD="column"
          $align="center"
          $justifyC="center"
          $mFlexD="row"
          $mMargin="5vh 0 0 0"
          // $border="2px solid red"
        >
          <Line
            $bColor="main"
            $height="50%"
            $mWidth="50%"
            $width="0"
            // $margin="5vh 0 0 0"
          />
          <TxtSpan
            $font="fontThrid"
            $fSize="4vh"
            $mFSize="3vh"
            $color="secColor"
            $padding=".5vh"
          >
            Of
          </TxtSpan>
          <Line
            $bColor="main"
            $height="50%"
            $mWidth="50%"
            $width="0"
            // $margin="0 0 5vh 0"
          />
        </FlexContainer>
        {/* Contact form */}
        <ContactForm />
      </FlexContainer>
    </FlexContainer>
  );
};

export default ContactOns;

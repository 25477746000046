import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () =>  {
    const location = useLocation();

    useEffect(() => {
        const sectionId = location.state?.sectionId;
        if (sectionId) {
            const sectionElement = document.getElementById(sectionId);
            if (sectionElement) {
                sectionElement.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return null; // This component does not render anything
}

export default ScrollToTop;

import React from "react";
import {
  AbsTxtSpan,
  DynamicLabel,
  FlexContainer,
  IsRequiredStar,
  Select,
} from "../../Styles/Global.Styled";
import { theme } from "../../Styles/theme";

const SelectInput = ({
  width,
  mWidth,
  height,
  mHeight,
  properties,
  setForm,
  index,
}) => {
  const { label, required, value, errors, disabled, options } =
    properties ?? {};
  
  const runValidation = (e) => {
    if (required && options.includes(e.target.value)) {
      return setForm((prev) => {
        const formData = [...prev];
        formData[index].errors = false;
        return formData;
      });
    }
    return setForm((prev) => {
      const formData = [...prev];
      formData[index].errors = `${label} is required`;
      return formData;
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    console.log("SelectInput label value", value);
    setForm((prev) => {
      const formData = [...prev];
      console.log("hfkasj", formData);
      formData[index].value = value;
      return formData;
    });
    // if (errors !== null) {
    //   runValidation(e);
    // }
  };
  return (
    <FlexContainer
      $width={width}
      $mWidth={mWidth}
      $height={height}
      $mHeight={mHeight}
    >
      <Select
        $border={`1px solid ${errors ? "red" : theme.secColor}`}
        value={value}
        id={label}
        required={required}
        disabled={disabled}
        onChange={handleChange}
        onBlur={runValidation}
        $color="black"
        $fSize="1.75vh"
        $padding="0 .5vw"
        style={{ zIndex: "1", backgroundColor: "transparent" }}
      >
        <option value="" disabled />
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </Select>
      {value ? (
        <DynamicLabel
          $position="absolute"
          $height="1.5vh"
          $top="-1vh"
          $left="1vh"
          $fSize="1.75vh"
          $zIndex="1"
          $bgColor="white"
        >
          {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      ) : (
        <DynamicLabel
          $position="absolute"
          $height="95%"
          $fSize="1.75vh"
          $padding="0 1vh"
        >
          Selecteer één {label}
          {required ? <IsRequiredStar /> : null}
        </DynamicLabel>
      )}
      {errors && (
        <AbsTxtSpan
          $color="red"
          $fSize="1.25vh"
          $mFSize="1.75vh"
          $height="1.5vh"
          $bottom="-1.9vh"
        >
          {errors}
        </AbsTxtSpan>
      )}
    </FlexContainer>
  );
};

export default SelectInput;

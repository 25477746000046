import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomButton,
  FlexContainer,
  H1,
  ItemAfter,
  ItemBefore,
} from "../../Styles/Global.Styled";
import { servicesObject } from "../../helpers/data";
import { theme } from "../../Styles/theme";
import styled, { css } from "styled-components";
import ServiceCard from "./ServiceCard";
import { smoothHorizontalScrolling } from "../../helpers/functions";

const ServiceButton = styled(CustomButton)`
  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.5vh;
    font-size: 2.2vh;
  }
  ${({ $isActive }) =>
    $isActive
      ? css`
          text-decoration: underline;
          text-underline-offset: 0.5vh;
          font-size: 2.2vh;
        `
      : null}
`;

const Diensten = ({ hideTitle = false, allowChangeFocus = false }) => {
  const [activeService, setService] = useState(0);
  const [activeSubService, setActiveSubService] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null); // New state to track hover
  const buttonContainerRef = useRef(null); // Ref for the container of buttons
  const cardsContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (hoverIndex === null) {
      // Only cycle automatically when not hovering
      const subServices = servicesObject[activeService].services;
      const subServiceInterval = setInterval(() => {
        setActiveSubService((prevActiveSubService) => {
          const nextSubService =
            (prevActiveSubService + 1) % subServices.length;
          if (nextSubService === 0) {
            setService(
              (prevActiveService) =>
                (prevActiveService + 1) % servicesObject.length
            );
          }
          return nextSubService;
        });
      }, 3000); // Change interval here if needed

      return () => clearInterval(subServiceInterval);
    }
  }, [activeService, hoverIndex]); // React to changes in activeService and hoverIndex

  // Horizontal scroll for service buttons
  useEffect(() => {
    const container = buttonContainerRef.current;
    let timeoutId; // Variable to hold the timeout ID

    if (container && container.children[activeService]) {
      const activeButton = container.children[activeService];
      const containerWidth = container.offsetWidth;
      const buttonWidth = activeButton.offsetWidth;
      const buttonLeft = activeButton.offsetLeft;

      // Desired position for the scroll
      const scrollPosition = buttonLeft + buttonWidth / 2 - containerWidth / 2;

      // Call smooth scroll
      smoothHorizontalScrolling(
        container,
        500,
        container.scrollLeft,
        scrollPosition
      );
      if (allowChangeFocus) {
        // After scrolling, set focus to the container
        timeoutId = setTimeout(() => {
          container.focus();
          container.scrollIntoView({ behavior: "smooth", block: "start" }); 
        }, 500); // Ensure this runs after the smooth scrolling is done}
      }
    }
    // Cleanup function to clear timeout
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [activeService, allowChangeFocus]);

  useEffect(() => {
    if (
      allowChangeFocus &&
      cardsContainerRef.current &&
      window.innerWidth <= 600
    ) {
      // Assuming 600px is your mobile breakpoint
      const activeCard = cardsContainerRef.current.children[activeSubService];
      if (activeCard) {
        activeCard.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [activeSubService, allowChangeFocus]);

  const handleHoverChange = (index) => {
    setHoverIndex(index);
    if (index !== null) {
      setActiveSubService(index); // Directly set active sub-service on hover
    }
  };

  return (
    <FlexContainer
      $padding="5vh 10vh"
      $mPadding="5vh 0"
      style={{ zIndex: "2" }}
      $flexD="column"
      $width="100%"
      $align="center"
      id="onze-diensten"
    >
      <H1
        $color="main"
        hidden={hideTitle}
        $fSize="4.5vh"
        $margin="0 0 5vh 0"
        $mMargin="0 0 1vh 0"
      >
        Onze diensten
      </H1>
      {/* Main service buttons */}
      <FlexContainer
        ref={buttonContainerRef}
        $gap="2.5vh"
        $mPadding="0 22vh"
        $padding="0 5vh"
        $align="center"
        $justifyC="center"
        $mJustifyC="flex-start"
        $mWidth="100%"
        $width="100%"
        $mOverflow="auto"
      >
        {servicesObject.map((service, index) => {
          const isSelected = activeService === index;
          return (
            <ServiceButton
              key={service.id}
              $isActive={isSelected}
              $width={isSelected ? "27vh" : "22vh"}
              $height={isSelected ? "12vh" : "10vh"}
              $mPadding="0 3vh"
              $bRadius={isSelected ? "6px 6px 0 0" : "6px"}
              $color={isSelected ? "secColor" : "main"}
              $fSize="2vh"
              $bgColor={isSelected ? "main" : "secColor"}
              $fWeight="600"
              onClick={() =>
                isSelected
                  ? navigate(`/onze-diensten/${service.id}`)
                  : setService(index)
              }
            >
              {isSelected && (
                <ItemBefore
                  $right="100%"
                  $top="7vh"
                  $size=".85vh .85vh"
                  $width="4vh"
                />
              )}
              {service.name}
              {isSelected && (
                <ItemAfter
                  $left="100%"
                  $top="7vh"
                  $size="-.85vh .85vh"
                  $width="4vh"
                />
              )}
            </ServiceButton>
          );
        })}
      </FlexContainer>
      {/* List of sub-services */}
      <FlexContainer
        ref={cardsContainerRef}
        $border={`8px solid ${theme.main}`}
        $mBorder={`4px solid ${theme.main}`}
        // $mFlexD="column"
        $width="100%"
        $bRadius="12px"
        $bgColor="mainShadow"
        $mFlxWrap="wrap"
      >
        {servicesObject[activeService].services.map((service, index) => (
          <ServiceCard
            key={index}
            index={index}
            service={service}
            isActive={index === activeSubService}
            serviceId={servicesObject[activeService].id}
            onHover={handleHoverChange}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Diensten;
